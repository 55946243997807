import React from 'react';
import { Provider } from 'react-redux';

import CrossingLocaleBanner from './CrossingLocaleBanner';
import CrossingLocaleModal from './CrossingLocaleModal';
import { connectLocation } from './LocationConnectors';
import { isLocaleMatch } from './locales';
import { store } from '../Store';

/*
 * top level component for CrossingLocale (flow for when a page has a locale that doesn't agree with the user's negotiated locale)
 * not to be confused with the CrossingLocale embeddable widget in components/Widgets/CrossingLocale
 */
export const CrossingLocale = connectLocation(
  ({ locale, userLocale, postalRequired, availableLocales }) => {
    const mismatched = !isLocaleMatch(locale, userLocale);

    if ((availableLocales?.length || 0) < 1) {
      return null;
    }
    if (!mismatched && !postalRequired) {
      return null;
    }

    if (postalRequired) {
      return <CrossingLocaleModal />;
    }

    return <CrossingLocaleBanner />;
  }
);

const ProvidedCrossingLocale = () => (
  <Provider store={store}>
    <CrossingLocale />
  </Provider>
);

export default ProvidedCrossingLocale;
