import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { InputWrapper } from './InputWrapper';
import { SelectDropDown } from '../../Builder/Library/Choices';
import Label from '../../ComponentLibrary/input-elements/oc-label';
import Image from '../../OC/oc-image';
import { INTERNATIONAL } from '../locales';

const CountryOption = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: left;

  img {
    width: 38px;
    margin-right: 15px;
  }
`;

export function makeCountryOption({ code, countryName, flag }, compact) {
  return {
    value: code.split('-')[1] || INTERNATIONAL,
    label: (
      <CountryOption>
        <Image
          src={flag}
          alt={code}
          style={
            compact
              ? { width: '20px', marginLeft: '8px', marginRight: '8px' }
              : { marginRight: '15px' }
          }
        />
        <span>{countryName}</span>
      </CountryOption>
    ),
  };
}

export const CountryPicker = ({ value, options, setCountry, label }) => (
  <InputWrapper>
    <Label text={label} />
    <SelectDropDown
      value={value}
      options={options}
      onChange={option => setCountry(option.value)}
      validated
    />
  </InputWrapper>
);

const optionShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
});

CountryPicker.propTypes = {
  value: optionShape.isRequired,
  options: PropTypes.arrayOf(optionShape).isRequired,
  setCountry: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  compact: PropTypes.bool,
};

CountryPicker.defaultProps = {
  compact: false,
};
