import PropTypes from 'prop-types';
import { Component } from 'react';

import Contents from './Contents';
import trySubmitZip from './trySubmitZip';
import Modal from '../../Modal';
import { localeType } from '../CrossingLocaleBanner';
import { connectLocation } from '../LocationConnectors';
import { makeT } from '../locales';

const LocationModalView = ({
  open,
  closeLocationModal,
  isSwitcher,
  mismatched,
  onSubmit,
  onChangeZip,
  showLocale,
  showZip,
  error,
  locale,
  t,
  availableLocales,
  zip,
  setLocaleCode,
}) => (
  <Modal
    className="location-modal"
    name="Region Selector"
    open={open}
    onClose={closeLocationModal}
    dismissible={isSwitcher}
    size="sm"
  >
    <Contents
      isSwitcher={isSwitcher}
      mismatched={mismatched}
      onSubmit={onSubmit}
      onChangeZip={onChangeZip}
      showLocale={showLocale}
      showZip={showZip}
      error={error}
      locale={locale}
      t={t}
      availableLocales={availableLocales}
      zip={zip}
      setLocaleCode={setLocaleCode}
    />
  </Modal>
);

LocationModalView.propTypes = {
  open: PropTypes.bool.isRequired,
  closeLocationModal: PropTypes.func,
  isSwitcher: PropTypes.bool.isRequired,
  mismatched: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onChangeZip: PropTypes.func.isRequired,
  showLocale: PropTypes.bool.isRequired,
  showZip: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  locale: localeType.isRequired,
  t: PropTypes.func.isRequired,
  availableLocales: PropTypes.arrayOf(localeType).isRequired,
  zip: PropTypes.string,
  setLocaleCode: PropTypes.func.isRequired,
};

LocationModalView.defaultProps = {
  closeLocationModal: () => {},
  mismatched: false,
  error: false,
  zip: '',
};

class LocationModalController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      zip: props.zip,
      locale: props.locale,
    };
  }

  componentDidMount() {
    // If locale selection is disabled, force first available locale
    if (!this.props.showLocale && this.props.availableLocales.length > 0) {
      this.updateLocation({ locale: this.props.availableLocales[0] });
    }
  }

  onSuccess(locale, zip) {
    this.setState({ error: null });
    this.props.changeUserLocation({ locale, zip });
    this.props.closeLocationModal();
  }

  onSubmit() {
    const { locale, zip } = this.state;
    const { postalRequired, showZip } = this.props;
    trySubmitZip(
      locale,
      zip,
      postalRequired || showZip,
      (loc, zi) => this.onSuccess(loc, zi),
      () => this.setState({ error: true })
    );
  }

  updateLocation(attributes) {
    this.setState(({ zip, locale }) => ({ zip, locale, ...attributes }));
  }

  render() {
    const {
      locationModalOpen,
      closeLocationModal,
      userLocale,
      showLocale,
      postalRequired,
      availableLocales,
      mismatched,
      showZip,
      isSwitcher = true,
      address,
    } = this.props;
    const shouldShowZip = showZip || postalRequired;
    const open = isSwitcher
      ? locationModalOpen // switcher is toggled by redux
      : mismatched || (postalRequired && address?.unconfirmed); // crossing modal open depends on condition
    const t = makeT(this.state.locale.code);

    return (
      <LocationModalView
        onChangeZip={z => this.updateLocation({ zip: z?.toUpperCase() })}
        onSubmit={() => this.onSubmit()}
        showLocale={showLocale}
        showZip={shouldShowZip}
        setLocaleCode={localeCode =>
          this.updateLocation({
            locale: availableLocales.find(l => l.code === localeCode),
          })
        }
        open={open}
        closeLocationModal={closeLocationModal}
        availableLocales={availableLocales}
        isSwitcher={isSwitcher}
        mismatched={mismatched}
        userLocale={userLocale}
        t={t}
        error={this.state.error}
        zip={this.state.zip}
        locale={this.state.locale}
      />
    );
  }
}

LocationModalController.propTypes = {
  locationModalOpen: PropTypes.bool.isRequired,
  closeLocationModal: PropTypes.func.isRequired,
  changeUserLocation: PropTypes.func.isRequired,
  userLocale: localeType.isRequired,
  showLocale: PropTypes.bool.isRequired,
  showZip: PropTypes.bool.isRequired,
  postalRequired: PropTypes.bool,
  availableLocales: PropTypes.arrayOf(localeType).isRequired,
  mismatched: PropTypes.bool.isRequired,
  isSwitcher: PropTypes.bool,
  locale: localeType.isRequired,
  zip: PropTypes.string.isRequired,
  address: PropTypes.shape({
    unconfirmed: PropTypes.bool,
  }).isRequired,
};

LocationModalController.defaultProps = {
  isSwitcher: true,
  postalRequired: true,
};

export default connectLocation(LocationModalController);
