/* eslint-disable default-param-last */
import _ from 'lodash';
import { connect } from 'react-redux';

import { makeT, localeNumberFormat } from './locales';
import {
  LOCATION_CHANGE,
  LOCATION_MODAL_TOGGLE,
  CHANGE_USER_LOCATION,
} from '../Actions';
import { setLocaleCookie } from '../PageBuilder/helpers/cookies';
import getReduxInitial from '../helpers/redux_initial';

const DEFAULT = { locale: { code: 'en-US' } };
const locationInitial = getReduxInitial('location', DEFAULT);

export const locationReducer = (state = locationInitial, action) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { zip, locale, address } = action.location;
      return _.assign({}, newState, { zip, locale, address });
    }
    case LOCATION_MODAL_TOGGLE: {
      const { locationModalOpen } = action;
      return _.assign({}, newState, { locationModalOpen });
    }
    case CHANGE_USER_LOCATION: {
      // for when user accepts a locale
      // change both userLocale and locale
      const address = { ...action.location.address };
      delete address.unconfirmed;
      setLocaleCookie(action.location?.locale?.code);
      return _.assign({}, newState, {
        userLocale: action.location.locale,
        zip: action.location.zip,
        locale: action.location.locale,
        address,
      });
    }
    default:
      return state;
  }
};

export const changeLocation = location => ({ type: LOCATION_CHANGE, location });
export const openLocationModal = () => ({
  type: LOCATION_MODAL_TOGGLE,
  locationModalOpen: true,
});
export const closeLocationModal = () => ({
  type: LOCATION_MODAL_TOGGLE,
  locationModalOpen: false,
});
export const changeUserLocation = location => ({
  type: CHANGE_USER_LOCATION,
  location,
});

export const connectLocation = connect(
  state => ({
    ...state.location,
    t: makeT(state.location.locale.code),
    tNumber: localeNumberFormat(state.location.locale.code),
  }),
  {
    changeLocation,
    openLocationModal,
    closeLocationModal,
    changeUserLocation,
  },
  null,
  { forwardRef: true }
);
