import React from 'react';
import { Provider } from 'react-redux';

import { connectLocation } from './LocationConnectors';
import LocationModalController from './Popup/Modal';
import { isLocaleMatch } from './locales';
import { store } from '../Store';

export const CrossingLocale = connectLocation(({ locale, userLocale }) => {
  const mismatched = !isLocaleMatch(locale, userLocale);

  return (
    <LocationModalController
      mismatched={mismatched}
      isSwitcher={false}
      postalRequired
      showZip
      showLocale
    />
  );
});

export default () => (
  <Provider store={store}>
    <CrossingLocale />
  </Provider>
);
