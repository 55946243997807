import { isLocaleMatch, localeSort } from './locales';

/*
 * first matching locale if any match
 * else first locale for same region
 * else first locale for same language
 * else first locale
 */
function bestLocaleMatch(userLocale, availableLocales) {
  const sortedLocales = [...availableLocales].sort(localeSort);
  let match = sortedLocales.find(l => isLocaleMatch(l, userLocale));
  if (match) {
    return match;
  }

  const [userLanguageCode, userRegionCode] = userLocale.code.split('-');
  if (userRegionCode) {
    match = sortedLocales.find(l => l.code.split('-')[1] === userRegionCode);
    if (match) {
      return match;
    }
  }

  match = sortedLocales.find(l => isLocaleMatch({ code: userLanguageCode }, l));
  if (match) {
    return match;
  }

  return sortedLocales[0];
}

export default bestLocaleMatch;
